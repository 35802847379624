import '/@admin:css/views/auth.css'

import { Vue } from 'vue-class-component'

import Application from '/@admin/shared/application'

class AuthLogin extends Vue {
  public updateEmail(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement
    const email = target.value

    if (email.length < 5) {
      return
    }

    try {
      localStorage.setItem('auth:login:email', email)
    } catch (e) {
      // ignore
    }
  }

  private setEmail(email: string) {
    const input = this.$refs.email as HTMLInputElement
    if (input.value.length === 0) {
      input.value = email
    }
  }

  mounted() {
    let email
    try {
      email = localStorage.getItem('auth:login:email')
    } catch (e) {
      // ignore
    }

    if (email) {
      this.setEmail(email)
    }
  }
}

export default new Application(AuthLogin)
